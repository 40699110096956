.basicSegment {
    width: 90%
}

@media (max-width: 991px) {
    .basicSegment {
        width: 100%
    }
}

.quill {
    height: 30% !important;
}


.ui.label {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.14285714em;
    background-color: #fff !important;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: #9f3a38 !important;;
    text-transform: none;
    font-weight: 700;
    border: 1px solid orange !important;
    border-radius: 0.28571429rem;
    transition: background .1s ease;
}
