.menuItemStyle {
    height: 50px!important;
    width: 150px!important;
    backgroundColor: gainsboro!important;
    padding: 10px!important;
}

.preloader {
    display: flex;
    justify-content: center;
}

.basicSegment {
    width: 90%
}

.hoverSegment:hover {
    background-color: lightgray !important;
    cursor: pointer;
}

.hoveredSegment {
    background-color: lightgray !important;
    cursor: pointer;

}

@media (max-width: 991px) {
    .basicSegment {
        width: 100%
    }
}


