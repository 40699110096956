.textarea {
    width: 40%;
}

.checkedComment {
    background-color: lightgreen!important;
    overflow-x: auto;
}

.comment {
    overflow-x: auto;
}

@media (max-width: 1000px) {
    .textarea {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .textarea {
        width: 100%;
    }
}
