.header {
    min-height: 47px!important;
}

@media (max-width: 995px) {
    .header {
        height: 93px!important;
    }
}

@media (max-width: 760px) {
    .header {
        height: auto!important;
    }
}
