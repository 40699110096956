.segmentStyle {
    width: 90%;
}

.coverLetter {
    width: auto;
}

.coverLetter:hover {
    background-color: lightgray !important;
    cursor: pointer;
}

.hoveredCoverLetter {
    background-color: lightgray !important;
    cursor: pointer;
}
