.triggerButton {
    margin-bottom: 2px!important;
    background-color: #3399ff;
}

.preloader {
    display: flex;
    justify-content: center;
}

@media (max-width: 886px) {
    .triggerButton {
        display: flex!important;
        justify-content: center!important;
    }
}

@media (max-width: 513px) {
    .triggerButton {
        display: inline-block!important;
    }
}
