.realmModal {
    height: 100%!important;
}

.preloader {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.buttons {
    minWidth: 100px;
    minHeight: 40px;
    maxHeight: 40px;
    padding: 10px;
}
