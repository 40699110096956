logo {
    width: 200px!important;
    height: 68px!important;
}

.greenLabel {
    left: 60%!important;
}

@media (max-width: 766px) {
    .logo {
        display: flex;
        justify-content: center;
    }
}
