
.preloader {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.inputStyle {
    border: 2px solid #3399ff;
    width: 17rem;
}

