.buttonClose {
    float: left;
}


@media (max-width: 410px) {
    .buttonClose {
        float: left;
    }
}
